import React, {useRef, useState, useEffect} from "react";
import AOS from "aos";
import Loader from "./../../components/Loader/Loader";
import dataNav from "../../data/Navbar/nav-appstyle-data.json";
import HeaderSeven from "../../components/Header/HeaderSeven";
import FooterTwo from "../../components/Footer/FooterTwo";


const AvisosJS = () => {
    const home = useRef();
    const feature = useRef();
    const featureStaff = useRef();
    const pricing = useRef();
    const review = useRef();
    const contact = useRef();

    const scrollToSection = (e, content) => {
        e.preventDefault();
        switch (content) {
            case "home":
                home.current.scrollIntoView({behavior: "smooth"});
                break;
            case "feature":
                feature.current.scrollIntoView({behavior: "smooth"});
                break;
            case "featureStaff":
                featureStaff.current.scrollIntoView({behavior: "smooth"});
                break;
            case "pricing":
                pricing.current.scrollIntoView({behavior: "smooth"});
                break;
            case "review":
                review.current.scrollIntoView({behavior: "smooth"});
                break;
            case "contact":
                contact.current.scrollIntoView({behavior: "smooth"});
                break;
            default:
        }
    };
    const [toggler, setToggler] = useState(false);
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);


    return (
        <Loader>
            <div style={{paddingLeft: 48, paddingRight: 48, paddingTop: 24}}>
                <div style={{fontWeight: 'bold', color: 'black', fontSize: 32}}>
                   Terminos y condiciones
                </div>
                <div>
                    Ekole App, con domicilio en San José #322, Fracc. La Primavera, Culiacán, Sinaloa; C.P. 80199 y como
                    medio de contacto en el correo electrónico contacto@Ekole App.app, quien se compromete a respetar lo
                    establecido en el presente aviso de privacidad, mismo que está puesto a su disposición en
                    cumplimiento en lo establecido en la Ley Federal de Protección de DatosPersonales en Posesión de los
                    Particulares, en su reglamento y en los lineamientos de aviso de privacidad publicados en el Diario
                    Oficial de la Federación con fecha 17 de enero de 2013 y es aplicable respecto a los datos
                    personales de la personas físicas que Ekole App obtiene motivo de sus actividades y los servicios
                    que proporciona, como son alertas a los usuarios cuando los dispositivos registrados en Ekole App,
                    se encuentran dentro del área determinada, notificaciones por medios electrónicos relacionadas al
                    servicio, encuestas de satisfacción, cobranza e información de tráfico vehicular en la zona
                </div>
                <br/>
                <div style={{fontWeight: 'bold', color: 'black', fontSize: 24}}>
                    Declaraciones.
                </div>
                <div>
                    Los datos pueden ser recopilados por los usuarios a través de www.ekole.app Usted (EL TITULAR)
                    declara que está aceptando los términos y las condiciones contenidos en este aviso y declara y
                    otorga expresamente su aceptación y consentimiento utilizando para tales efectos medios
                    electrónicos, en términos de lo dispuesto por el artículo1803 del Código Civil Federal.
                </div>

                Si EL TITULAR no acepta en forma absoluta y completa los términos y condiciones de este aviso, deberá
                abstenerse de compartir cualquier tipo de información a Ekole App, por cualquier medio incluyendo la APP
                Ekole App. Las partes acuerdan que al no existir error, dolo, mala fe o cualquier otro vicio de la
                voluntad que pudiera nulificar la validez del presente instrumento, ambasacuerdan en sujetarse al tenor
                de lo estipulado en los siguientes: Definiciones.:
                <div style={{fontWeight: 'bold', color: 'black', fontSize: 24, paddingTop: 24}}>
                    Datos personales.
                </div>
                <br/>
                <div>

                    Cualquier información concerniente a una persona física identificada o identificable.
                     EL TITULAR. La persona física (EL TITULAR) a quien identifican o corresponden los datos
                    personales.

                     USUARIO. La institución educativa, que proporciona los datos personales a Ekole App.RESPONSABLE.
                    Ekole App que sola o conjuntamente con otras, decide sobre el tratamiento de los datos personales.

                     ENCARGADO. Ekole App que sola o conjuntamente con otras, trate datos personales por cuenta del
                    responsable.

                     TRATAMIENTO. La obtención, uso, acceso, manejo, aprovechamiento, transferencia o disposición de
                    datos personales, divulgación o almacenamiento de datos personales por cualquier medio

                    . TRANSFERENCIA. Toda comunicación de datos realizada a persona distinta del responsable o
                    encargado del tratamiento.

                     TERCERO. La persona física o moral, nacional o extranjera, distinta del titular o del responsable
                    de los datos.

                     DERECHOS ARCO. Derechos de Acceso, Rectificación, Cancelación y Oposición.

                     CONSENTIMIENTO TÁCITO. Se entenderá que EL TITULAR ha consentido en el tratamiento de los datos,
                    cuando habiéndose puesto a su disposición el Aviso dePrivacidad, no manifieste su oposición

                     FINALIDADES PRIMARIAS. Aquellas finalidades para las cuales se solicitan principalmente los datos
                    personales y por lo que se da origen a la relación entre Ekole App y EL TITULAR, entre otras:


                    - Dar cumplimiento a los requerimientos de diversas autoridades.-

                    Cumplir con las actividades y servicios de Ekole App.

                    - Realizar actividades de mercadotecnia, publicidad y análisis de datos y comportamiento de mercado.

                    - Dar cumplimiento a obligaciones contraídas con nuestros clientes.

                    - Conocer gustos y preferencias conforme al uso del servicio.

                    - Informar sobre cambios de nuestros productos o servicios.

                    EL TITULAR reconoce y acepta que Ekole App obtendrá por medio de los usuarios, los siguientes datos
                    personales, tales como:

                    Nombre
                    Fecha de nacimiento
                    Edad
                    Correo electrónico
                    Número de celular.
                    Nivel escolar.
                    Grado escolar.
                    Salón


                    EL TITULAR en este acto, otorga su consentimiento expreso en términos del artículo 8 de la LFPDPPP,
                    y los artículos 11, 12, 13 y demás relativos y aplicables del LFPDPPP, para que Ekole App trate sus
                    datos personales contenidos en esta cláusula, para cumplir con las finalidades que establece el
                    presente Aviso de Privacidad.

                </div>
                <div>

                    Ekole App en ningún caso almacena información de datos personales sensibles como origen racial o
                    étnico,
                    estado de salud actual, información genética, creencias religiosas, filosóficas y morales,
                    afiliación
                    sindical, opiniones políticas, preferencia sexual, biométricos, etc.

                    SOBRE EL RESPONSABLE DEL TRATAMIENTO DE DATOS PERSONALES. El responsable de ltratamiento de sus
                    datos
                    personales es Ekole App, los datos que recabarán serán únicamente los necesarios para prestar el
                    servicio por usted requerido.

                    En caso de que EL TITULAR no desee que sus datos personales ya no sean utilizados para
                    lasFinalidades
                    establecidas en el presente aviso de privacidad, deberá enviar una solicitud de eliminación de sus
                    datos
                    al siguiente correo electrónico: contacto@Ekole App.app.

                </div>

                <div style={{fontWeight: 'bold', color: 'black', fontSize: 24, paddingTop: 24}}>
                    Limitaciones para el acceso y divulgación de los datos personales.
                </div>

                Ekole App se compromete a realizar su
                mejor esfuerzo para proteger la seguridad de los datos personales que EL TITULAR le está entregando,
                mediante la celebración de actos jurídicos y el efectivo uso de tecnologías que controlen el acceso, uso
                o divulgación sin autorización de la información personal. Para tal efecto, se almacena la información
                personal en bases de datos con acceso limitado que se encuentran en instalaciones controladas con
                mecanismos de seguridad. Ekole Appse compromete a que la información proporcionada por EL TITULAR sea
                considerada con carácter confidencial, y utilizada bajo plena privacidad.

                En este tenor, Ekole App en algunas ocasiones podrá fungir como el Encargado o Tercero, y por lo tanto
                se obliga a tomar las medidas necesarias para garantizar que se cumplan con las finalidades establecidas
                en el presente Aviso de Privacidad.

                DERECHOS DE ARCO En caso de que EL TITULAR necesite revocar su consentimiento, así comoAcceder,
                Rectificar, Cancelar u Oponerse al tratamiento de los datos personales que ha proporcionado, lo deberá
                hacer a través del departamento designado por Ekole App, en el correo electrónico contacto@Ekole
                App.app.


                <div style={{fontWeight: 'bold', color: 'black', fontSize: 24, paddingTop: 24}}>
                    Medios para revocar el consentimiento el titular.
                </div>
                <div>
                    Se podrá revocar el consentimiento de los datos
                    personales que se otorgan con la aceptación del presente, dicha revocación del consentimiento que se
                    otorga por medios electrónicos se deberá de hacer observando el siguiente procedimiento:

                    Enviar un correo electrónico en atención a contacto@ekole.app del presente Aviso, mediante el
                    cual
                    serán atendidas dichas solicitudes.

                    Los elementos que deberá contener el correo son:

                    El nombre completo del TITULAR, domicilio y correo electrónico para recibir la respuesta que se
                    genere
                    con motivo de su solicitud; el motivo de su solicitud; los argumentos que sustenten su solicitud o
                    petición; documento oficial que acredite su identidad y que demuestre que es quien dice ser; y fecha
                    a
                    partir de la cual, se hace efectiva la revocación de su consentimiento.

                    Ekole App notificará a EL TITULAR, en un plazo máximo de 20 (veinte) días, contados desde la fecha
                    en
                    que se recibió la solicitud sobre el ejercicio de los DERECHOS ARCO, la resolución adoptada, a
                    efecto de
                    que, si resulta procedente, se haga efectiva la misma dentro de los 15(quince) días siguientes a la
                    fecha en que se comunica la respuesta, mediante un mensaje que contenga que ha ejecutado de todos
                    los
                    actos tendientes a no tratar los datos personales de EL TITULAR.
                </div>


                <div style={{fontWeight: 'bold', color: 'black', fontSize: 24, paddingTop: 24}}>
                    Medios para ejercer los derechos arco.
                </div>
                <div>
                    En caso de que EL TITULAR necesite acceder, rectificar, cancelar
                    u oponerse a los datos personales que ha proporcionado a Ekole App, El TITULAR deberá seguir el
                    procedimiento descrito en el párrafo anterior.
                </div>

                <div style={{fontWeight: 'bold', color: 'black', fontSize: 24, paddingTop: 24}}>
                    Transferencia de datos personales.
                </div>

                <div>

                    Asimismo, le informamos que sus datos personales pueden ser
                    transferidos y tratados dentro y fuera del país, por personas distintas a esta empresa. En ese
                    sentido,
                    su información puede ser compartida con afiliadas, subsidiarias, controladoras, asociados,
                    organizadores
                    de eventos, patrocinadores, publicistas, contratistas, socios comerciales o sociedades integrantes
                    de
                    Ekole App, que le provean de servicios necesarios para su debida operación. En dichos supuestos, le
                    informamos que Ekole App. adoptará las medidas necesarias para que las personas que tengan acceso a
                    sus
                    datos personales cumplan con las políticas de privacidad de Ekole App que existan o puedan existir,
                    así
                    como los principios de protección de datos personales establecidos por la ley. Al no oponerse a
                    dicho
                    tratamiento, se da por entendido que autoriza a Ekole App a transferir sus datos personales a
                    cualquier
                    tercero(persona física o moral), el cual asumirá las mismas obligaciones que correspondan en el
                    tratamiento de los datos.

                </div>

                <div style={{fontWeight: 'bold', color: 'black', fontSize: 24, paddingTop: 24}}>
                    Ley aplicable y jurisdicción.
                </div>
                <div>
                    Las partes expresan que el presente aviso, se regirá por las disposiciones
                    legales aplicables en la República Mexicana, en especial, por lo dispuesto en laLey Federal de Datos
                    Personales en Posesión de los Particulares y su reglamento. Para el caso de que exista una disputa o
                    controversia, derivada de la interpretación, ejecución o cumplimiento del aviso o de cualquiera de
                    los
                    documentos que del mismo se deriven, o que guarden relación con éste, las partes amigablemente
                    buscarán
                    llegar a un acuerdo dentro de un plazo de 30 (treinta) días naturales, contados a partir de la fecha
                    en
                    que surja cualquier diferencia y se notifique por escrito sobre dicho evento a la contraparte.

                    En caso de que las partes no lleguen a un acuerdo, convienen en este acto en someter todas las
                    desavenencias que deriven del presente AVISO o de cualquiera de los documentos que del mismo se
                    deriven,
                    o que guarden relación con éste o con aquéllos, serán resueltas de manera definitiva se someten a la
                    competencia y Leyes de las Autoridades Administrativas Federales oTribunales de la Ciudad de
                    Culiacán,
                    Sinaloa, renunciando expresamente a cualquier fuero distinto que por razones de sus domicilios
                    presentes
                    o futuros pudieren corresponderles.

                    De vez en cuando es posible que necesitemos hacer cambios, las políticas de privacidad y otra
                    información y/o modificar la funcionalidad de nuestro sitio web y se lo notificaremos a su correo
                    electrónico que registro como usuario de nuestras plataformas. Si usted tiene alguna pregunta sobre
                    esta
                    declaración de privacidad, por favor póngase en contacto con nosotros, enviando un correo
                    electrónico:
                    contacto@ekole.app.

                    <div style={{fontWeight: 'bold', color: 'black', fontSize: 24, paddingTop: 24}}>
                        Fecha de actualización: Septiembre 04 del 2023
                    </div>
                </div>
            </div>
            <FooterTwo/>
        </Loader>
    );
};

export default AvisosJS;

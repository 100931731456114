import React from "react";
import logoEkole from "../assets/images/qq.png";
import logoEkoleScroll from "../assets/images/qq.png";

const MainLogo = ({ collapse, showMenu }) => {
  return (
    <div className="navbar-header">
      <div className="logo">
        <a href={process.env.PUBLIC_URL}>
          <img className="logo logo-display" src={logoEkole} alt="" />
          <img className="logo logo-scrolled" src={logoEkoleScroll} alt="" />
        </a>
      </div>
    </div>
  );
};

export default MainLogo;

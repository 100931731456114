import React from "react";


const FooterTwo = () => {

    const handleOpenPDF = (url) => {
      window.open(url, '_blank');
    };

    return (
        <section className="dark-bg flat-footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <ul className="social-media">
                            <button onClick={() => handleOpenPDF("/")}>
                                Inicio
                            </button>
                            <span className="spacecustom">|</span>
                            <button onClick={() => handleOpenPDF("/terms-and-conditions")}>
                                Términos y Condiciones
                            </button>
                            <span className="spacecustom">|</span>
                            <button onClick={() => handleOpenPDF("/privacy-and-policy")}>
                                Políticas de Privacidad
                            </button>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default FooterTwo;

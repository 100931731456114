import React, { forwardRef } from "react";
import PriceItemApp from "./PriceItemApp";

const PricingAppStyle = forwardRef((props, ref) => (
  <section className="pt-0 white-bg skew-bg" id="pricing" ref={ref}>
    <div className="container">
      <div className="row">
        <div className="col-md-4 col-sm-12">
          <div
            className="pt-50 pb-50 text-center text-sm-start"
            data-aos-delay={100}
            data-aos={"fade-up"}
          >
            <h1 className="font-700">Nuestros precios</h1>
            <div className="mt-30">
              <p>
              Si quieres conocer los precios o estás interesado en que
              se implemente en tu escuela y requieres de mayor información, favor de llenar este
              formulario y un agente estará en contacto contigo de inmediato.
              </p>
            </div>
          </div>
        </div>
        <PriceItemApp
            classAppend="mb-20"
          />
      </div>
    </div>
  </section>
));

export default PricingAppStyle;

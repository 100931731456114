import AvisosJS from "./pages/Docs/Avisos";
import AppStyle from "./pages/home/AppStyle";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import PrivacyAndPolicy from "./pages/Docs/PrivacyAndPolicy";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<AppStyle/>}/>
        <Route exact path="/terms-and-conditions" element={<AvisosJS/>}/>
        <Route exact path="/privacy-and-policy" element={<PrivacyAndPolicy/>}/>
      </Routes>
    </Router>
  );
}

export default App;

import React, { useState } from "react";

const PriceItemApp = (classAppend) => {
  const [inputs, setInputs] = useState({});
  const [familyGuy, setFamilyGuy] = useState();
  const [workSchool, setWorkSchool] = useState();
  const [privateSchool, setPrivateSchool] = useState();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const handleSend = (e) => {
    e.preventDefault()
    var texto = `Hola, mi ciudad es ${inputs.name} y mi numero es ${inputs.phone } y me interesa ponerme en contacto con ustedes, Padre de familia: ${familyGuy ? "Si" : "No"}, Trabajo en escuela: ${workSchool ? "Si" : "No"}, Mi escuela es privada: ${privateSchool ? "Si" : "No"}`;
    window.open(`https://api.whatsapp.com/send?phone=5216674922953&text=${texto}`,'_blank');
  }
  return (
  <div
    className={"col-md-4 col-sm-12 " + (classAppend ? classAppend : "")}
    data-aos-delay="100"
    data-aos={"fade-up"}
  >
    <div className="pricing-box">
      <h3 className="font-700">Contáctanos</h3>
      <form name="contactprices-form" id="contactprices-form" className="app-form" onSubmit={handleSend}>
      <div
          className="col-md-6 col-sm-6"
          data-aos={"fade-up"}
          data-aos-delay={100}
          data-aos-duration={700}
        >
        <label htmlFor="familyGuy">¿Eres padre de familia?</label>
        </div>
      <div
          className="col-md-4 col-sm-4"
          data-aos={"fade-up"}
          data-aos-delay={100}
          data-aos-duration={700}
        >
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="familyGuy" id="inlineRadio1" value={familyGuy} required="required" onChange={() => setFamilyGuy(true)}/>
          <label class="form-check-label" for="inlineRadio1">Si</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="familyGuy" id="inlineRadio2" value={!familyGuy} required="required" onChange={() => setFamilyGuy(false)}/>
          <label class="form-check-label" for="inlineRadio2">No</label>
        </div>
        </div>
        <div
          className="col-md-6 col-sm-6"
          data-aos={"fade-up"}
          data-aos-delay={100}
          data-aos-duration={700}
        >
        <label htmlFor="workSchool">¿Trabajas en una escuela?</label>
        </div>
      <div
          className="col-md-4 col-sm-4"
          data-aos={"fade-up"}
          data-aos-delay={100}
          data-aos-duration={700}
        >
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="workSchool" id="inlineRadio3" value={workSchool} required="required" onChange={() => setWorkSchool(true)}/>
          <label class="form-check-label" for="inlineRadio3">Si</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="workSchool" id="inlineRadio4" value={!workSchool} required="required" onChange={() => setWorkSchool(false)}/>
          <label class="form-check-label" for="inlineRadio4">No</label>
        </div>
        </div>
        <div
          className="col-md-6 col-sm-6"
          data-aos={"fade-up"}
          data-aos-delay={100}
          data-aos-duration={700}
        >
        <label htmlFor="pivateSchool">¿Tu escuela es privada?</label>
        </div>
      <div
          className="col-md-4 col-sm-4"
          data-aos={"fade-up"}
          data-aos-delay={100}
          data-aos-duration={700}
        >
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="pivateSchool" id="inlineRadio5" value={privateSchool} required="required" onChange={() => setPrivateSchool(true)}/>
          <label class="form-check-label" for="inlineRadio5">Si</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="pivateSchool" id="inlineRadio6" value={!privateSchool} required="required" onChange={() => setPrivateSchool(false)}/>
          <label class="form-check-label" for="inlineRadio6">No</label>
        </div>
        </div>

        <div className="row">
        <div
          className="col-md-12 col-sm-12"
          data-aos={"fade-up"}
          data-aos-delay={100}
          data-aos-duration={700}
        >
          <div className="form-floating">
            <input
              type="text"
              name="name"
              className="form-control"
              id="name"
              required="required"
              placeholder="Tu nombre"
              data-error="Tu nombre es requerido"
              value={inputs.name}
              onChange={handleInputChange}
            />
            <label htmlFor="name">Nombre de tu ciudad</label>
            <div className="help-block with-errors mt-20"></div>
          </div>
        </div>
        </div>
        <div className="row">
        <div
          className="col-md-12 col-sm-12"
          data-aos={"fade-up"}
          data-aos-delay={100}
          data-aos-duration={700}
        >
          <div className="form-floating">
            <input
              type="number"
              name="phone"
              className="form-control"
              id="phone"
              required="required"
              placeholder="Tu numero"
              data-error="Tu numero es requerido"
              value={inputs.phone}
              onChange={handleInputChange}
            />
            <label htmlFor="name">Numero de telefono</label>
            <div className="help-block with-errors mt-20"></div>
          </div>
        </div>
        </div>
        <div className="pricing-box-bottom">
      <button
          type="submit"
          name="submit"
          className="btn btn-color btn-circle"
        >
          Enviar mensaje
        </button>
      </div>
      </form>
    </div>
  </div>
  );
};

export default PriceItemApp;

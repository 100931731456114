import React, {useEffect} from "react";
import AOS from "aos";
import Loader from "./../../components/Loader/Loader";
import FooterTwo from "../../components/Footer/FooterTwo";


const PrivacyAndPolicy = () => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <Loader>
            <div style={{paddingLeft: 98, paddingRight: 98, paddingTop: 24}}>
                <div style={{fontWeight: 'bold', color: 'black', fontSize: 32}}>
                    Política de Privacidad
                </div>
                <div style={{color: 'black', paddingTop: 16}}>
                    Los Presentes políticas de privacidad regulan el uso y acceso que tengan los usuarios a las
                    aplicaciones, sitio web, herramientas, redes sociales y otros mecanismos, que Ekole App utilice para
                    otorgar los servicios, Ekole App se reserva el derecho a cambiar estas condiciones en cualquier
                    momento,
                    que aplicaran inmediatamente después de publicadas.
                </div>
                <div style={{color: 'black', paddingTop: 16}}>
                    De no aceptar las políticas de privacidad o estar de acuerdo con ellas, se pide no hacer uso de los
                    servicios que Ekole App pone a su alcance. Al ingresar y utilizar las políticas de privacidad
                    que el usuario, ha leído, comprendido y aceptado los términos y condiciones que ponemos a su
                    disposición, el usuario confirma que tiene la capacidad legal para celebrar los actos jurídicos
                    acorde a la legislación mexicana.
                </div>

                <div style={{color: 'black', paddingTop: 16}}>
                    El contenido de la información para otorgar el servicio, es proporcionado por el usuario, por lo
                    que el usuario es responsable del uso o mal uso que le dé a Ekole App, a través de sus
                    credenciales y
                    contraseñas exclusivas para el otorgamiento del servicio, por lo que un uso distinto a sus fines,
                    autoriza a Ekole App a suspender la cuenta o negar el servicio, así como iniciar acciones legales
                    correspondientes, bajo ninguna circunstancia Ekole App será responsable de cualquier daño directo
                    o
                    indirecto, consecuencial, incidental, legal, por el uso indebido de Ekole App por parte del usuario.

                </div>
                <div style={{color: 'black', paddingTop: 16}}>
                    Ekole App no será responsable de errores u omisiones en el contenido de la página web
                    www.ekole.app,
                    todo contenido e información proporcionada no representa garantía de estar libres de errores,
                    omisiones, virus u otros elementos dañinos para su equipo.
                </div>
                <div style={{color: 'black', paddingTop: 16}}>
                    Ekole App queda liberado cualquier perdida y/o daño producido como consecuencia del mal uso que se
                    le
                    pueda dar al servicio, en ningún caso será responsable de que se atribuya el uso del servicio a la
                    comisión de delitos, responsabilidad penal, responsabilidad civil, accidentes vehiculares,
                    lesiones, secuestros, muerte u otros delitos de manera enunciativa más nos limitativa que existan o
                    pudieran existir.
                </div>
                <div style={{color: 'black', paddingTop: 16}}>
                    El uso de la Ekole App está protegido por las leyes en
                    materia de propiedad intelectual, por lo que
                    la
                    utilización no implica el otorgamiento de ningún derecho de titularidad sobre propiedad
                    intelectual, propiedad industrial, nombre, marca, logos, queda prohibido descargar, copiar,
                    publicar, modificar, distribuir material y/o contenido de Ekole App, el uso indebido o interferir en
                    el
                    funcionamiento de Ekole App derivara en acciones legales hacia el usuario.
                </div>
                <div style={{color: 'black', paddingTop: 16}}>
                    Contar con el servicio de Ekole App no otorga derechos patrimoniales, de propiedad intelectual,
                    industrial u otros, que no sean el correcto uso del servicio durante la vigencia que sea contratada,
                    durante este periodo o posterior el usuario tiene prohibido la creación, diseños, realización de
                    material, relacionado, basado o que incluya contenido de Ekole App, por lo que la realización estas
                    conductas implicaran una afectación directa a Ekole App, mismas que se generaran reparación del
                    daño
                    y acciones legales contra el usuario u otros que realicen estos actos.
                </div>


                <div style={{fontWeight: 'bold', color: 'black', fontSize: 24}}>
                    Los servicios
                </div>
                <div style={{color: 'black', paddingTop: 16}}>
                    Ekole App atiende la problemática del tráfico vehicular en las instalaciones de los usuarios,
                    indicando cuando un dispositivo registrado en Ekole App se acerca al área, a través de la
                    recopilación de datos que los usuarios registran, Ekole App notifica a los usuarios por medios
                    electrónicos lo relacionado con el uso del servicio, cobranza, publicidad y encuestas de
                    satisfacción.
                </div>
                <div style={{color: 'black', paddingTop: 16}}>

                    El usuario registrara los datos necesarios para el servicio, e indicara a Ekole App el número de
                    cuentas que necesitara, que serán asignadas a cada beneficiario.
                </div>
                <div style={{color: 'black', paddingTop: 16}}>
                    La licencia para utilizar Ekole App puede ser de forma semestral, anual o permanente y los costos
                    serán
                    establecidos conforme al tipo de licencia que el usuario dese adquirir.

                </div>


                <div style={{fontWeight: 'bold', color: 'black', fontSize: 24, paddingTop: 24}}>
                    Ley aplicable y jurisdicción.
                </div>
                <div style={{color: 'black', paddingTop: 16}}>
                    Las Política de Privacidad están regidos por las leyes de los Estados Unidos Mexicanos.
                    Para la interpretación, cumplimiento y ejecución de los presentes términos y condiciones, las
                    partes se someten a la jurisdicción de los tribunales competentes en la Ciudad de Culiacán,
                    Sinaloa, renunciando a cualquier otro fuero que pudiera corresponderles en razón de sus domicilios
                    resentes o futuros.

                </div>

                <div style={{fontWeight: 'bold', color: 'black', fontSize: 24, paddingTop: 24}}>
                    Garantia.
                </div>
                <div style={{color: 'black', paddingTop: 16}}>
                    Ekole App no se hace responsable de daños o perjuicios que el usuario sufra a consecuencia de la
                    utilización del servicio, en ese sentido Ekole App no asumirá ningún tipo de responsabilidad
                    frente a ninguna persona, derivado del contenido o servicio recibido.

                </div>

                <div style={{color: 'black', paddingTop: 16}}>
                    Ekole App no se hace responsable de daños o perjuicios que el usuario sufra a consecuencia de la
                    utilización del servicio, en ese sentido Ekole App no asumirá ningún tipo de responsabilidad
                    frente a ninguna persona, derivado del contenido o servicio recibido.

                </div>


                <div style={{fontWeight: 'bold', color: 'black', fontSize: 24, paddingTop: 24}}>
                    Uso de ubicación en segundo plano
                </div>
                <div style={{color: 'black', paddingTop: 16}}>

                    Esta Política de Privacidad describe cómo Ekole App recopila, utiliza y protege la información de
                    ubicación y otros datos personales de los usuarios de nuestra aplicación.
                </div>
                <div style={{fontWeight: 'bold', color: 'black', fontSize: 24, paddingTop: 24}}>
                    Información que Recopilamos
                </div>
                <div style={{color: 'black', paddingTop: 16}}>

                    Ekole App recopila información de ubicación en segundo plano para notificar a las escuelas que están
                    por recolectar un alumno.
                </div>

                <div style={{fontWeight: 'bold', color: 'black', fontSize: 24, paddingTop: 24}}>
                    Uso de la Información
                </div>
                <div style={{color: 'black', paddingTop: 16}}>

                    Utilizamos la información de ubicación para enviar una notificación al padre de que ya esta cerca de
                    la zona de recoleción de su hijo y además notifica a la escuela que ya están por llegar por el
                    alumno. Los datos de registro se utilizan para mejorar la calidad
                    de nuestro servicio.
                </div>

                <div style={{fontWeight: 'bold', color: 'black', fontSize: 24, paddingTop: 24}}>
                    Compartir Información
                </div>
                <div style={{color: 'black', paddingTop: 16}}>

                    No compartimos su información de ubicación con terceros sin su consentimiento explícito, a menos que
                    lo exija la ley.
                </div>

                <div style={{fontWeight: 'bold', color: 'black', fontSize: 24, paddingTop: 24}}>
                    Seguridad de los Datos
                </div>
                <div style={{color: 'black', paddingTop: 16}}>

                    Nos comprometemos a proteger sus datos de ubicación y otros datos personales. Utilizamos medidas de
                    seguridad estándar de la industria para garantizar la confidencialidad de su información.
                </div>
                <div style={{fontWeight: 'bold', color: 'black', fontSize: 24, paddingTop: 24}}>
                    Sus Derechos
                </div>
                <div style={{color: 'black', paddingTop: 16}}>

                    Usted tiene el derecho de desactivar la ubicación en segundo plano en cualquier momento a través de
                    la configuración de la aplicación. También puede contactarnos para solicitar la eliminación de sus
                    datos personales.
                </div>

                <div style={{fontWeight: 'bold', color: 'black', fontSize: 24, paddingTop: 24}}>
                    Cambios en la Política de Privacidad
                </div>
                <div style={{color: 'black', paddingTop: 16}}>

                    Podemos actualizar esta Política de Privacidad ocasionalmente para reflejar cambios en nuestras
                    prácticas. Le notificaremos sobre cualquier cambio importante.
                </div>

                <div style={{fontWeight: 'bold', color: 'black', fontSize: 24, paddingTop: 24}}>
                    Póngase en Contacto con Nosotros
                </div>
                <div style={{color: 'black', paddingTop: 16}}>

                    Si tiene alguna pregunta o inquietud sobre nuestra política de privacidad, no dude en ponerse en
                    contacto con nosotros en contacto@ekole.app
                </div>


                <div style={{fontWeight: 'bold', color: 'black', fontSize: 24, paddingTop: 24}}>
                    Fecha de actualización: Septiembre 04 del 2023
                </div>

            </div>
            <FooterTwo/>
        </Loader>
    );
};

export default PrivacyAndPolicy;

import React, { forwardRef } from "react";
import ContactFormThree from "../ContactFormThree";
import Icofont from "react-icofont";

const ContactApp = forwardRef((props, ref) => (
  <>
    <section className="contact-us white-bg" id="contact" ref={ref}>
      <div className="container">
        <div className="row">
          <div className="col-sm-6 section-heading">
            <h2 className="font-700">
              Pregúntanos cualquier cosa y te responderemos pronto 
            </h2>
          </div>
        </div>
        <div className="row mt-50">
          <div className="col-md-8 offset-md-2">
            <ContactFormThree />
          </div>
        </div>
      </div>
    </section>
    <section className="p-0">
      <div className="container-fluid">
        <div className="row row-flex">
          <div className="col-md-6">
            <div className="col-inner spacer gradient-bg">
              <div className="text-center white-color">
                <Icofont icon="email" className="font-50px white-icon" />
                <h2>Correo</h2>
                <p className="mb-0">contacto@ekole.app</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="col-inner spacer dark-bg">
              <a href="https://api.whatsapp.com/send?phone=5216674922953&text=Hola,%20quiero%20contactarme%20con%20ustedes">
              <div className="text-center white-color">
                <Icofont
                  icon="google-iphone"
                  className="font-50px white-icon"
                />
                <h2>Envíamos un whatsapp</h2>
                <p className="mb-0">(+52) 667 3187676</p>
              </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
));

export default ContactApp;
